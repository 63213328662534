<template>
  <div>
    <v-container
      v-if="checkPrivilege('company:read')"
      fluid
      pa-0
      class="company-component-wrapper"
    >
      <div v-if="loadedCount < 9">
        <app-section-loader :status="loadedCount < 9" />
      </div>
      <div v-else>
        <v-row no-gutters>
          <company-header-filter
            :search-bar="searchBar"
            :show-all-company="showAllCompany"
            :deleteable-company="deleteableCompany"
            @read:csv="checkImportCompanies($event)"
            @import:company="getCompanyList()"
            @change:search-bar="filterCompanies($event)"
            @switch:company-filter="showAllCompany = !$event"
            @delete:companies="deleteCompanies()"
          />
        </v-row>
        <v-row no-gutters>
          <company-table
            :loading="loading"
            :company-list="companyList"
            :search-bar="searchBar"
            :company-patterns="companyPatterns"
            :show-all-company="showAllCompany"
            :show-user-bank="userBankList.length > 1"
            @open:company="showCompany($event)"
          />
        </v-row>
      </div>
    </v-container>
    <div class="company-dialog-area">
      <base-dialog
        ref="dialogDelete"
        :title="$t('message.delete')"
        :message="$t('message.company_list_delete_message')"
        :cancel-text="$t('message.modal_cancel_btn')"
        :submit-text="$t('message.table_delete_btn')"
        @submit="$emit('delete')"
      />
      <v-container>
        <v-dialog
          v-model="showCompanyEditForm"
          class="company-company-detail-dialog"
          @click:outside="$router.push('/company')"
        >
          <v-row no-gutters>
            <v-col xs="12" md="5">
              <v-card
                tile
                outlined
                flat
                style="overflow-y: scroll"
                class="company-company-detail-dialog"
              >
                <v-toolbar flat height="50" class="border-bottom-1 mb-2">
                  会社情報
                </v-toolbar>
                <v-card-text style="height: calc(90vh-100px)">
                  <company-profile
                    ref="companyProfile"
                    :editable="editableCompany"
                    :company="company"
                    :user-bank-list="userBankList"
                  />
                </v-card-text>
                <v-divider />
                <v-toolbar flat height="50">
                  <v-icon
                    v-if="deleteableCompany"
                    class="fs-16 mx-3"
                    style="cursor: pointer"
                    @click.native="deleteCompany(company.id)"
                  >
                    ti-trash
                  </v-icon>
                  <v-spacer />
                  <v-btn
                    small
                    class="mx-2"
                    @click="
                      showCompanyEditForm = false
                      $router.push('/company')
                    "
                  >
                    キャンセル
                  </v-btn>
                  <v-btn
                    v-if="editableCompany"
                    color="primary"
                    small
                    @click="updateCompany()"
                  >
                    {{ $t('message.submit') }}
                  </v-btn>
                </v-toolbar>
              </v-card>
            </v-col>
            <v-col
              xs="12"
              md="7"
              style="overflow-y: scroll; background-color: white"
              class="company-company-detail-dialog pa-4"
            >
              <v-card
                v-for="(pattern, index) in companyPatterns"
                :key="`pattern-${index}`"
                class="mb-4"
              >
                <company-pattern-card
                  :name="pattern.name"
                  :user-organization="pattern.userOrganization"
                  :journals="pattern.journals"
                  :keyword="pattern.keyword"
                >
                  <template #actions>
                    <v-spacer />
                    <v-btn
                      v-show="companyPatterns.length !== 1 && editableCompany"
                      small
                      @click="onDeletePattern(pattern)"
                      v-text="'削除'"
                    />
                    <v-btn
                      small
                      :disabled="!editableCompany"
                      @click="copyPattern(pattern)"
                      v-text="'コピー'"
                    />
                    <v-btn
                      small
                      :disabled="!editableCompany"
                      @click="addPattern(company.id)"
                      v-text="'追加'"
                    />
                    <v-btn
                      small
                      color="primary"
                      @click="editPattern(pattern)"
                      v-text="editableCompany ? '編集' : '閲覧'"
                    />
                  </template>
                </company-pattern-card>
              </v-card>
            </v-col>
          </v-row>
        </v-dialog>
      </v-container>
      <v-dialog v-model="showPatternEditForm" scrollable max-width="550px">
        <company-journal-pattern
          :editable="editableCompany"
          :company-pattern="selectedPattern"
          :operation="operationOfCompanyPattern"
          :user-organizations="userOrganizations"
          @operate:record="operateRecode($event)"
          @change:journal="onChangeJournal($event)"
          @cancel="showPatternEditForm = false"
          @submit="savePattern($event)"
        />
      </v-dialog>
      <company-import-error ref="importErrorDialog" :errors="importErrors" />
      <import-widget
        :companies="importWidgetCompanies"
        :show="importWidgetSettings.show"
        :loading="importWidgetSettings.loading"
        @change:show="importWidgetSettings.show = $event"
        @click:import="importCompanies($event)"
      />
    </div>
  </div>
</template>
<style scoped>
.company-company-detail-dialog {
  max-height: 90vh;
  height: 90vh;
}
.company-component-wrapper {
  height: calc(100vh - 215px);
}
</style>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'

import Snackbar from 'Helpers/snackbar/index'

import CompanyHeaderFilter from '@/pages/master/Company/@components/@shared/CompanyHeaderFilter/CompanyHeaderFilter'
import CompanyTable from '@/pages/master/Company/@components/@shared/CompanyTable/CompanyTable'
import CompanyProfile from '@/pages/master/Company/@components/@shared/CompanyProfile/CompanyProfile'
import CompanyJournalPattern from '@/pages/master/Company/@components/@shared/CompanyJournalPattern/CompanyJournalPattern'
import CompanyPatternCard from '@/pages/master/Company/@components/@shared/CompanyPatternCard/CompanyPatternCard'
import CompanyImportError from '@/pages/master/Company/@components/@shared/CompanyImportError/CompanyImportError'

import ImportWidget from '@/pages/master/Company/@components/ImportWidget/ImportWidget'

import { getFormCompanyModel } from '@/objects/company/companyForm'
import { getCompanyPatternModel } from '@/objects/company/companyPattern'

import {
  getCompanies,
  companyService,
  getCompanyFromSnake,
  companyBatchService,
} from '@/services/company/company'
import { companyListService } from '@/services/company/companyList'
import { companyJournalService } from '@/services/company/companyJournal'
import { CompanyPatternService } from '@/services/company/companyPattern'
import { deepCopy } from 'Helpers/helpers'
import { onChangeJournal } from 'Helpers/journal'
import { InputStateDiscriminator } from 'Helpers/helpers'
import { importCompaniesService } from '@/services/company/csv'
import { convertForImportWidget } from 'Helpers/company'

const inputStateDiscriminator = new InputStateDiscriminator()

export default {
  components: {
    CompanyHeaderFilter,
    CompanyTable,
    CompanyProfile,
    CompanyJournalPattern,
    CompanyPatternCard,
    CompanyImportError,
    ImportWidget,
  },
  data() {
    return {
      loading: true,
      searchBar: '',
      showAllCompany: false,
      companyList: [],
      userOrganizations: [],
      userBankList: [],
      showCompanyEditForm: false,
      showPatternEditForm: false,
      operationOfCompanyPattern: 'edit',
      company: getFormCompanyModel(),
      companyPatterns: [],
      selectedPattern: getCompanyPatternModel(),
      importErrors: [],
      importWidgetCompanies: [],
      importWidgetSettings: {
        loading: false,
        show: false,
      },
    }
  },
  computed: {
    ...mapGetters({
      loadedCount: 'loadedCount',
      authToken: 'getAuthToken',
    }),
    editableCompany() {
      return this.checkPrivilege('company:update') && !this.company.readonly
    },
    deleteableCompany() {
      return this.checkPrivilege('company:delete')
    },
  },
  created() {
    if (this.$route.params.id) {
      getCompanies(this.authToken, { id: this.$route.params.id }).then(
        (companies) => {
          this.showCompany(companies[0])
          this.$router.replace('/company')
        }
      )
    }
    this.getCompanyList()
    this.getUserOrgList()
    this.getUserBankList()
  },
  methods: {
    async getCompanyList() {
      this.loading = true
      try {
        this.companyList.length = 0
        const companyList = await companyListService.getByCompanyView(
          this.authToken,
          this.$store.getters.getOrganizationID
        )
        this.companyList.push(...companyList)
        this.loading = false
      } catch (error) {
        this.loading = true
      }
    },
    async deleteCompany(companyId) {
      const yes = await this.$confirm.deleteWithInput({
        message: 'この学習データを削除します。よろしいですか？',
      })
      if (!yes) {
        return
      }
      this.showCompanyEditForm = false
      companyService
        .delete(this.authToken, companyId)
        .then(() => {
          Snackbar.success(this.$t('message.success'))
          const index = this.companyList.findIndex((x) => x.id === companyId)
          this.companyList.splice(index, 1)
        })
        .catch(() => {
          Snackbar.error(this.$t('message.unexpectedError'))
        })
    },
    updateCompany() {
      const refCompanyProfile = this.$refs.companyProfile
      if (!(refCompanyProfile && refCompanyProfile.validateForm())) {
        return Snackbar.error('入力情報に不備があります。再度ご確認ください。')
      }
      const { company } = this

      const changeCompanyList = (changedCompany) => {
        const index = this.companyList.findIndex((x) => x.id === company.id)
        this.$set(this.companyList, index, changedCompany)
      }
      companyService
        .update(this.authToken, company)
        .then((changedCompany) => {
          Snackbar.success('会社情報を変更しました。')
          changeCompanyList(changedCompany)
          this.showCompanyEditForm = false
        })
        .catch(() => {
          Snackbar.error(this.$t('message.unexpectedError'))
        })
    },
    showCompany(argCompany) {
      this.$set(this, 'company', getCompanyFromSnake(argCompany))
      this.showCompanyEditForm = true
      const companyId = argCompany.id
      getCompanies(this.authToken, { id: companyId }).then((companies) => {
        const company = companies[0]
        const companyPatterns = company.patterns.map((v) => {
          return {
            id: v.id,
            name: v.name,
            // department: v.department || {},
            userOrganization: v.user_organization || {},
            keyword: v.keyword,
            journals: v.company_journals,
            companyId,
          }
        })
        const formCompany = getCompanyFromSnake(company)
        this.$set(this, 'company', formCompany)
        this.$set(this, 'companyPatterns', companyPatterns)
      })
    },
    filterCompanies(searchBar) {
      inputStateDiscriminator.input(searchBar).then(({ state, text }) => {
        if (state === 'done') {
          this.searchBar = text
        }
      })
    },
    addPattern(companyId) {
      const newPattern = getCompanyPatternModel()
      newPattern.companyId = companyId
      this.$set(this, 'selectedPattern', newPattern)
      this.showPatternEditForm = true
      this.operationOfCompanyPattern = 'add'
    },
    copyPattern(patternFrom) {
      const patternTo = deepCopy(patternFrom)
      patternTo.id = 0
      this.$set(this, 'selectedPattern', patternTo)
      this.operationOfCompanyPattern = 'copy'
      this.showPatternEditForm = true
    },
    editPattern(pattern) {
      this.operationOfCompanyPattern = 'edit'
      this.$set(this, 'selectedPattern', deepCopy(pattern))
      this.showPatternEditForm = true
    },
    async createPattern(pattern) {
      try {
        const createdPattern = await CompanyPatternService.create(
          this.authToken,
          pattern
        )
        pattern.id = createdPattern.id
        this.companyPatterns.push(pattern)
        Snackbar.success('仕訳パターンを作成しました。')
      } catch (error) {
        Snackbar(this.$t('message.unexpectedError'))
      }
      this.showPatternEditForm = false
    },
    async updatePattern(pattern) {
      try {
        await CompanyPatternService.update(this.authToken, pattern)
        const index = this.companyPatterns.findIndex(
          (_pattern) => _pattern.id === pattern.id
        )
        this.$set(this.companyPatterns, index, pattern)
        Snackbar.success('仕訳パターンを更新しました。')
      } catch (error) {
        Snackbar(this.$t('message.unexpectedError'))
      }
      this.showPatternEditForm = false
    },
    savePattern(pattern) {
      if (this.operationOfCompanyPattern === 'edit') {
        this.updatePattern(pattern)
      } else {
        this.createPattern(pattern)
      }
    },
    async onDeletePattern(pattern) {
      const yes = await this.$confirm.deleteWithInput({
        message: 'この仕訳パターンを削除します。よろしいですか？',
      })
      if (!yes) {
        return
      }
      try {
        await CompanyPatternService.delete(this.authToken, pattern.id)
        const index = this.companyPatterns.findIndex(
          (companyPattern) => companyPattern.id === pattern.id
        )
        this.companyPatterns.splice(index, 1)
        Snackbar.success('削除しました。')
      } catch (error) {
        Snackbar(this.$t('message.unexpectedError'))
      }
    },
    deletePattern() {
      const deleteIndex = this.selectedPatternIndex
      this.selectedPatternIndex = 0
      this.companyPatterns.splice(deleteIndex, 1)
    },
    onChangeJournal(event) {
      if (event.field === 'sub_account') {
        event.field = 'subject'
      }
      if (event.obj === null) {
        event.obj = {}
      }
      onChangeJournal(event, event.journal)
    },
    operateRecode(event) {
      const index = event.journalIndex
      const journals = event.journals
      let messageKey
      switch (event.type) {
        case 'add':
          companyJournalService.add(journals, index, event.last)
          messageKey = 'addedJournal'
          break
        case 'copy':
          companyJournalService.copy(journals, index, event.last)
          messageKey = 'copyedJournal'
          break
        case 'clear':
          companyJournalService.clear(journals, index, event.all)
          messageKey = event.all ? 'clearedAllJournals' : 'clearedOneJournal'
          break
        case 'delete':
          companyJournalService.delete(journals, index, event.all)
          messageKey = event.all ? 'deletedAllJournals' : 'deletedOneJournal'
          break
      }
      Snackbar.success(this.$t(`message.${messageKey}`))
    },
    async deleteCompanies() {
      const selectedIds = this.companyList
        .filter((company) => company.selected)
        .map((company) => company.id)
      if (selectedIds.length === 0) {
        Snackbar.error('学習データを選択してください。')
        return
      }
      const yes = await this.$confirm.deleteWithInput({
        message: `${selectedIds.length}件の学習データを一括削除します。よろしいですか？`,
      })
      if (!yes) {
        return
      }

      companyListService
        .delete({ token: this.authToken, ids: selectedIds })
        .then(() => {
          Snackbar.success('学習データを一括削除しました。')
          const notSelectedCompanies = this.companyList.filter(
            (company) => !company.selected
          )
          this.$set(this, 'companyList', notSelectedCompanies)
        })
        .catch(() => {
          Snackbar(this.$t('message.unexpectedError'))
        })
    },
    async checkImportCompanies(csvData) {
      try {
        Snackbar.warning('インポートには少々お時間をいただく場合がございます。')
        const companies = await importCompaniesService.check(
          this.$store.getters.getAuthToken,
          csvData
        )
        Snackbar.close()
        const convertedCompanies = companies.map((company) =>
          convertForImportWidget(company)
        )
        this.$set(this, 'importWidgetCompanies', convertedCompanies)
        this.importWidgetSettings.show = true
      } catch (error) {
        Snackbar.close()
        this.importErrors.length = 0
        if (typeof error == 'string') {
          this.importErrors.push(error)
        } else {
          this.importErrors.push(...error)
        }
        this.$refs.importErrorDialog.open()
      }
    },
    async importCompanies(companies) {
      try {
        this.importWidgetSettings.loading = true
        for (const company of companies) {
          company.user_bank_id =
            (company.user_bank && company.user_bank.id) || undefined
        }
        await companyBatchService.bulkCreate(
          this.$store.getters.getAuthToken,
          companies
        )
        Snackbar.success('成功しました。')
        this.importWidgetSettings.show = false
        this.getCompanyList()
      } catch (e) {
        console.error(e)
        Snackbar.error(this.$t('message.unexpectedError'))
      }
      this.importWidgetSettings.loading = false
    },
    getUserOrgList() {
      try {
        this.userOrganizations = []
        let url = this.$store.getters.apiUserListFunction
        const auth_token = this.$store.getters.getAuthToken
        let user_role_id = this.$store.getters.getUserRole
        url +=
          '?organization_id=' +
          this.$store.getters.getOrganizationID +
          '&user_role_id=' +
          user_role_id
        axios
          .get(url, {
            headers: {
              Authorization: auth_token,
            },
          })
          .then((response) => {
            if (!response.data.error) {
              for (let userOrg of response.data.user_list) {
                this.userOrganizations.push({
                  id: userOrg.id,
                  name: userOrg.user.full_name,
                })
              }
            }
          })
      } catch (e) {
        console.log(e)
      }
    },
    getUserBankList() {
      this.$store.dispatch('getUserBankList').then((data) => {
        this.userBankList = data
      })
    },
  },
}
</script>
