const formCompany = {
  id: '',
  gensenzei: false,
  gensenCode: '',
  gensenTotalTargets: 0,
  companyName: '',
  companyAddress: '',
  companyPhonenumber: '',
  invoiceIssuerNumber: '',
  department: { id: 0, code: '' },
  payType: '',
  payMonth: '',
  payDay: '',
  userBank: {},
  paidByCustomer: false,
  bankName: '',
  bankBranchName: '',
  bankAccountType: '',
  bankAccountNumber: '',
  bankAccountName: '',
  patterns: [],
}

export function getFormCompanyModel() {
  return JSON.parse(JSON.stringify(formCompany))
}
