<template>
  <v-row no-gutters align="center" class="pt-0 pb-1" style="width: 100%">
    <v-col cols="2">
      <v-row no-gutters align="center">
        <journal-chip :text="chip" :tooltip="tooltip" :editable="editable" />
        <span v-if="descriptionField && editable" class="tw-pt-1">
          <v-menu offset-y nudge-bottom="25" transition="slide-y-transition">
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <s-icon class="tw-pl-1" icon="feather-info" />
              </div>
            </template>

            <v-card class="sweeep-popover" width="500">
              <v-card-title> %+N月% 入力について </v-card-title>
              <v-card-text>
                %+N月%（当月は%0月%）の入力で伝票日付から計算された月を出力できます。<br />
                例えば伝票日付に応じて毎回費用の発生月を登録したい場合に使用できます。<br />
                編集モードで閲覧中は表示が変わりませんのでご注意ください。<br />
                （例：伝票日付が2月の場合、「%+3月%分」と入力すると「5月分」を出力。）
              </v-card-text>
            </v-card>
          </v-menu>
        </span>
      </v-row>
    </v-col>
    <v-col cols="10" class="pl-2">
      <v-text-field
        v-show="editable"
        v-model="data"
        class="more-dense"
        dense
        filled
        outlined
        hide-details
        :clearable="editable"
        :error="mode === 2 && !value"
        :rules="getRules()"
        :background-color="setBackGround(mode === 2 && !value)"
        :tabindex="tabindex"
        @clear="data = ''"
      />
      <text-parser
        v-show="!editable"
        ref="textParser"
        :text="editable ? '' : data"
        class="fs-11 fw-bold pt-1"
      />
    </v-col>
  </v-row>
</template>
<script>
import JournalChip from 'Components/Journal/JournalChip.vue'
import TextParser from 'Components/Journal/TextParser.vue'

export default {
  components: {
    JournalChip,
    TextParser,
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    chip: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: Number,
      default: 1,
    },
    counter: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      default: null,
    },
    checkByte: {
      type: Boolean,
      default: false,
    },
    descriptionField: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      edit: false,
    }
  },
  computed: {
    data: {
      get() {
        return this.value
      },
      set(val) {
        if (!val) {
          this.$emit('change', '')
        } else {
          this.$emit('change', val)
        }
      },
    },
    computedHint: function () {
      let hint = ''
      if (this.checkByte) {
        hint = !this.counter ? '' : this.computedCounter + ' バイト'
      } else {
        hint = !this.counter ? '' : this.computedCounter + ' 文字'
      }
      return hint
    },
    computedCounter: function () {
      return this.getLength(this.data) + ' / ' + this.counter
    },
  },
  methods: {
    getRules() {
      try {
        const mode = this.mode // 1: 任意, 2: 必須
        const maxlen = this.counter
        const getLength = this.getLength
        let message

        if (!this.checkByte) {
          message = '文字以内で入力してください'
        } else {
          message = 'バイト以内で入力してください'
        }

        if (maxlen && mode === 1) {
          return [(v) => getLength(v) <= maxlen || maxlen + message]
        } else if (maxlen && mode === 2) {
          return [
            (v) =>
              (0 < getLength(v) && getLength(v) <= maxlen) ||
              '1以上かつ' + maxlen + message,
          ]
        } else if (mode === 2) {
          return [(v) => 0 < getLength(v) || '入力してください']
        } else {
          return []
        }
      } catch (e) {
        console.log(e)
        return []
      }
    },
    getLength(v) {
      try {
        if (!v) {
          return 0
        }
        if (!this.checkByte) {
          // 文字数で判定
          return v.length
        } else {
          // バイト数で判定
          return this.countLetters(v)
        }
      } catch (e) {
        console.log(e)
        return 0
      }
    },
    setBackGround(error) {
      if (error) {
        return '#FCE6DE'
      } else {
        return 'white'
      }
    },
    countLetters(text) {
      let count = 0
      for (let s of text) {
        if (s.match(/^[0-9a-zA-Z]+$/)) {
          // 半角文字
          count += 1
        } else if (s.match(/^[^\x01-\x7E\xA1-\xDF]+$/)) {
          // 全角文字
          count += 2
        } else {
          count += 2
        }
      }
      return count
    },
  },
}
</script>
