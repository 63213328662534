var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-5 w-100"},[(_vm.loading)?_c('div',[_c('app-section-loader',{attrs:{"status":_vm.loading}})],1):_c('div',{staticClass:"w-100"},[_c('v-alert',{staticClass:"fs-14",attrs:{"type":"info","height":"50px"}},[_vm._v("\n      請求元の会社一覧です。請求元名称をクリックすると会社に紐づく学習済の仕訳を変更できます。\n    ")]),_c('v-data-table',{ref:"companyTable",attrs:{"fixed-header":"","show-select":"","headers":_vm.computedHeaders,"items":_vm.filteredCompanyList,"search":_vm.searchBar,"options":_vm.options,"custom-sort":_vm.customSort,"footer-props":_vm.footerProps,"height":"calc(100vh - 280px)","item-key":"id"},on:{"update:options":function($event){_vm.options=$event},"update:footerProps":function($event){_vm.footerProps=$event},"update:footer-props":function($event){_vm.footerProps=$event}},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(ref){return [_c('select-all-header-cell',{attrs:{"items":_vm.filteredCompanyList},on:{"click:item":function($event){return _vm.onClickSelectMultiple($event.value)}}})]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-simple-checkbox',{model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.$emit('open:company', item)}}},[_vm._v(_vm._s(item.company_name))])]}},{key:"item.invoice_issuer_number",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.invoice_issuer_number? 'T' + item.invoice_issuer_number : '')+"\n      ")]}},{key:"item.bank_name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.bank_name)),_c('br'),(item.bank_code)?_c('span',{staticClass:"fw-normal"},[_vm._v("("+_vm._s(item.bank_code)+")")]):_vm._e()]}},{key:"item.bank_branch_name",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.bank_branch_name)),_c('br'),(item.bank_branch_code)?_c('span',{staticClass:"fw-normal"},[_vm._v("("+_vm._s(item.bank_branch_code)+")")]):_vm._e()]}},{key:"item.gensenzei",fn:function(ref){
var item = ref.item;
return [_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.gensenzei),expression:"item.gensenzei"}]},[_c('s-chip-dark',{staticClass:"px-3",attrs:{"color":"blue-500"}},[_vm._v(" 源泉 ")])],1)]}},{key:"item.user_bank",fn:function(ref){
var item = ref.item;
return [_vm._v("\n        "+_vm._s(item.user_bank.simpletext)+"\n      ")]}},{key:"item.paid_by_customer",fn:function(ref){
var item = ref.item;
return [(item.paid_by_customer)?_c('s-chip-dark',{staticClass:"px-3",attrs:{"color":"blue-500"}},[_vm._v("\n          先方\n        ")]):_vm._e()]}},(!_vm.loading)?{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-3",attrs:{"value":true,"color":"yellow","icon":"warning"}},[_vm._v("\n          "+_vm._s(_vm.$t('message.noResult'))+"\n        ")])]},proxy:true}:null],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }