<template>
  <base-dialog
    ref="dialog"
    title="インポートエラー"
    message="CSVデータの入力にエラーがあります"
    cancel-text="閉じる"
    hide-submit-btn
    size="large"
  >
    <template #card-content>
      <p v-for="(error, index) in errors" :key="`importError-${index}`">
        {{ error }}
      </p>
    </template>
  </base-dialog>
</template>

<script>
export default {
  model: {
    prop: 'showDialog',
    event: 'change',
  },
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
  methods: {
    open() {
      this.$refs.dialog.open()
    },
    close() {
      this.$refs.dialog.close()
    },
  },
}
</script>
