<template>
  <v-menu v-model="menu" :close-on-content-click="false">
    <template #activator="{ on }">
      <v-icon v-tooltip="'インポート書式について'" v-on="on">
        feather-info
      </v-icon>
    </template>
    <v-card class="sweeep-popover" width="720">
      <v-card-title>学習データインポートCSVデータの書式について</v-card-title>
      <v-card-text>
        <strong>1.以下の項目は必須です。</strong>
        <div class="fw-bold ml-2 mb-2">
          <li>ID</li>
          <li>請求元</li>
          <li>借方勘定科目</li>
        </div>
        <strong>2.次の項目は入力形式を守ってください。</strong>
        <v-simple-table class="mt-4" light>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-xs-left">項目</th>
                <th class="text-xs-left">入力形式</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in inputRules" :key="item.name">
                <td style="min-width: 160px">
                  {{ item.name }}
                </td>
                <td>
                  <div v-html="item.format" />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
const inputRules = [
  {
    name: 'ID',
    format: `
      IDは会社情報を識別するための重複のない識別コードです。<br>
      一つの会社情報に複数の仕訳を記入する際は同じIDを入力してください。<br>
      また、同じIDは連続する行に入力してください。
    `,
  },
  {
    name: '口座種別',
    format: '【普通】【当座】【貯蓄】【その他】から選択してください。',
  },
  {
    name: '支払方法',
    format:
      '【振込】【引落】【クレジット】【手形】【現金】【海外送金】【その他】から選択してください。',
  },
  {
    name: '支払サイト',
    format: `
    支払サイトは <strong> 【Xカ月後のY日】 </strong> の形式で入力してください。<br>
    但し、月末を指定する場合は<strong> 【Xカ月後月末】 </strong> を指定することが可能です。<br>
    0カ月後 1カ月後 2カ月後 を指定する場合は 【当月】、【翌月】、【翌々月】の指定が可能です。
    `,
  },
  {
    name: '手数料負担',
    format: `
      振込手数料先方負担として学習する場合は 1 を入力してください。<br>
      「振込手数料の先方負担指定」が選択されている場合のみ指定できます。
    `,
  },
  {
    name: '源泉',
    format: '源泉の対象として学習する場合は 1 を入力してください。',
  },
  {
    // 区分に変更
    name: '源泉の区分',
    format: [
      '以下のコードを指定してください。',
      '0 税理士等の報酬',
      '1 原稿料､著作権の使用料､放送謝金等',
      '2 診療報酬',
      '3 職業野球の選手等の報酬･料金',
      '4 職業拳闘家の報酬',
      '5 外交員等の報酬･料金',
      '6 映画､演劇の俳優等の報酬･料金',
      '7 芸能人の役務の提供を内容とする事業を行う個人の報酬･料金',
      '8 ホステス等の報酬･料金',
      '21 役務提供についての契約金',
      '31 広告宣伝のための賞金',
      '32 個人の馬主が受ける競馬の賞金',
      '41 公的年金等',
      '61 生命･損害保険契約等に基づく年金',
      '81 法人の馬主が受ける競馬の賞金',
    ].join('<br>'),
  },
  {
    name: '源泉の対象人数',
    format: '数値を入力してください。',
  },
  {
    name: '仕訳マスタ',
    format:
      '各仕訳マスタはコードを入力してください。<br>指定されたマスタの中でコードの重複する物が存在する場合はエラーになります。',
  },
]

export default {
  data() {
    return {
      menu: false,
      inputRules,
    }
  },
}
</script>
