<template>
  <div @click="$emit('click')">
    <template v-for="(object, i) in parsedList">
      <a
        v-if="object.type === 'link'"
        :key="`${object.text}-${i}`"
        @click.stop="onOpenLink(object.text)"
      >
        {{ object.text }}
      </a>
      <span v-else-if="object.text" :key="`${object.text}-${i}`">
        {{ object.text }}
      </span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    parsedList() {
      try {
        return this.parseText(this.text)
      } catch (e) {
        console.log(e)
        return []
      }
    },
  },
  methods: {
    onOpenLink(url) {
      window.open(url, '_blank', 'noopener')
    },
    parseText(text) {
      try {
        const getParsedList = (text) => {
          const urlPattern = new RegExp(/https?:/)
          const parsedList = []
          let space
          let matchObject = text.match(urlPattern)
          while (matchObject) {
            parsedList.push({
              type: 'text',
              text: text.slice(0, matchObject.index),
            })
            text = text.slice(matchObject.index)
            space = text.match(/[\x20\u3000\n]/)
            if (space) {
              parsedList.push({
                type: 'link',
                text: text.slice(0, space.index),
              })
              text = text.slice(space.index)
            } else {
              parsedList.push({ type: 'link', text })
              text = ''
            }
            matchObject = text.match(urlPattern)
            if (matchObject === null) {
              parsedList.push({ type: 'text', text })
            }
          }
          return parsedList
        }

        /** main **/
        const parsedList = getParsedList(text)
        if (parsedList.length) {
          return parsedList
        } else {
          return [{ type: 'text', text }]
        }
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
