<template>
  <div class="pa-5 w-100">
    <div v-if="loading">
      <app-section-loader :status="loading" />
    </div>
    <div v-else class="w-100">
      <v-alert type="info" class="fs-14" height="50px">
        請求元の会社一覧です。請求元名称をクリックすると会社に紐づく学習済の仕訳を変更できます。
      </v-alert>
      <v-data-table
        ref="companyTable"
        fixed-header
        show-select
        :headers="computedHeaders"
        :items="filteredCompanyList"
        :search="searchBar"
        :options.sync="options"
        :custom-sort="customSort"
        :footer-props.sync="footerProps"
        height="calc(100vh - 280px)"
        item-key="id"
      >
        <template #header.data-table-select="{}">
          <select-all-header-cell
            :items="filteredCompanyList"
            @click:item="onClickSelectMultiple($event.value)"
          />
        </template>

        <template #item.data-table-select="{ item }">
          <v-simple-checkbox v-model="item.selected" />
        </template>

        <template #item.company_name="{ item }">
          <a @click="$emit('open:company', item)">{{ item.company_name }}</a>
        </template>

        <template #item.invoice_issuer_number="{ item }">
          {{ item.invoice_issuer_number? 'T' + item.invoice_issuer_number : '' }}
        </template>

        <template #item.bank_name="{ item }">
          {{ item.bank_name }}<br /><span
            v-if="item.bank_code"
            class="fw-normal"
            >({{ item.bank_code }})</span
          >
        </template>

        <template #item.bank_branch_name="{ item }">
          {{ item.bank_branch_name }}<br /><span
            v-if="item.bank_branch_code"
            class="fw-normal"
            >({{ item.bank_branch_code }})</span
          >
        </template>

        <template #item.gensenzei="{ item }">
          <span v-show="item.gensenzei">
            <s-chip-dark color="blue-500" class="px-3"> 源泉 </s-chip-dark>
          </span>
        </template>

        <template #item.user_bank="{ item }">
          {{ item.user_bank.simpletext }}
        </template>

        <template #item.paid_by_customer="{ item }">
          <s-chip-dark
            v-if="item.paid_by_customer"
            color="blue-500"
            class="px-3"
          >
            先方
          </s-chip-dark>
        </template>

        <template v-if="!loading" #no-data>
          <v-alert :value="true" color="yellow" icon="warning" class="mt-3">
            {{ $t('message.noResult') }}
          </v-alert>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import SelectAllHeaderCell from 'Components/Table/SelectAllHeaderCell'
import { applyMultiSelect } from 'Helpers/table'

export default {
  components: {
    SelectAllHeaderCell,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    companyList: {
      type: Array,
      required: true,
    },
    searchBar: {
      type: String,
      required: true,
    },
    companyPatterns: {
      type: Array,
      required: true,
    },
    showAllCompany: {
      type: Boolean,
      required: true,
    },
    showUserBank: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogEdit: false,
      dialogDelete: false,
      selected: [],
      options: {
        page: 1,
        itemsPerPage: 200,
      },
      footerProps: {
        'items-per-page-text': '表示件数',
        'items-per-page-options': [200, 500, 1000],
      },
    }
  },
  computed: {
    editable() {
      return this.checkPrivilege('company:update')
    },
    filteredCompanyList() {
      //初回ロード readonly = false 古い学習データを表示する on 全てfetch -> 以降はキャッシュとして使うの方が良いかもしれない
      return this.companyList.filter(
        (company) => this.showAllCompany || !company.readonly
      )
    },
    tableHeaders() {
      return [
        {
          // 1
          text: this.$t('message.company_table_company_name'),
          value: 'company_name',
          width: '250px',
          display: true,
        },
        {
          // 2
          text: this.$t('message.company_table_company_address'),
          value: 'company_address',
          display: true,
        },
        {
          // 3
          text: this.$t('message.company_table_company_phonenumber'),
          value: 'company_phonenumber',
          display: true,
        },
        {
          // 2
          text: this.$t('message.company_table_invoice_issuer_number'),
          value: 'invoice_issuer_number',
          display: true,
        },
        {
          // 4
          text: this.$t('message.company_table_gensenzei'),
          value: 'gensenzei',
          display: true,
        },
        {
          // 5
          text: this.$t('message.company_table_pay_site'),
          value: 'pay_site_name',
          display: true,
        },
        // { // 6
        //   text: this.$t('message.bank_code'),
        //   value: "bank_code",
        //   display:true
        // },
        {
          // 7
          text: this.$t('message.bank_name'),
          value: 'bank_name',
          display: true,
        },
        // { // 8
        //   text: this.$t('message.bank_branch_code'),
        //   value: "bank_branch_code",
        //   display:true
        // },
        {
          // 9
          text: this.$t('message.bank_branch_name'),
          value: 'bank_branch_name',
          display: true,
        },
        {
          // 10
          text: this.$t('message.bank_account_type'),
          value: 'bank_account_type',
          display: true,
        },
        {
          // 11
          text: this.$t('message.bank_account_number'),
          value: 'bank_account_number',
          display: true,
        },
        {
          // 12
          text: this.$t('message.bank_account_name'),
          value: 'bank_account_name',
          display: true,
        },
        {
          // 13
          text: '振込元銀行',
          value: 'user_bank',
          display: this.showUserBank,
        },
        {
          // 14
          text: '手数料負担',
          value: 'paid_by_customer',
          display: this.$store.getters.settingPaidByCustomer,
        },
      ]
    },
    computedHeaders: function () {
      return this.tableHeaders.filter((h) => h.display)
    },
  },
  methods: {
    getFilteredTable() {
      return this.$refs.companyTable.selectableItems
    },
    onClickSelectMultiple(type) {
      const selectFuncFactory = (bool) => {
        return (companyList) => {
          for (const company of companyList) {
            company.selected = bool
          }
        }
      }
      applyMultiSelect(type, this.getFilteredTable(), {
        ...this.options,
        selectFunction: selectFuncFactory(true),
        disSelectFunction: selectFuncFactory(false),
      })
    },
    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index == 'user_bank') {
          a.userBankID = a.user_bank.id ? a.user_bank.id : 0
          b.userBankID = b.user_bank.id ? b.user_bank.id : 0
          if (!isDesc[0]) {
            return a.userBankID < b.userBankID ? -1 : 1
          } else {
            return b.userBankID < a.userBankID ? -1 : 1
          }
        } else {
          if (!isDesc[0]) {
            return a[index] < b[index] ? -1 : 1
          } else {
            return b[index] < a[index] ? -1 : 1
          }
        }
      })
      return items
    },
  },
}
</script>
