import CompanyPatternClient from '@/clients/company/companyPattern'
const getId = (obj) => (obj && obj.id) || null
export const CompanyPatternService = {
  async update(token, pattern) {
    const data = this.createPatternBody(pattern)
    const response = await CompanyPatternClient.put({
      token,
      id: pattern.id,
      data,
    })
    return response
  },
  async create(token, pattern) {
    const data = this.createPatternBody(pattern)
    data.company_id = pattern.companyId
    const response = await CompanyPatternClient.post({ token, data })
    return response
  },
  async delete(token, patternId) {
    const response = await CompanyPatternClient.delete({ token, id: patternId })
    return response
  },
  createPatternBody(pattern) {
    const { id, keyword, name, journals } = pattern
    return {
      id,
      keyword,
      name,
      journals: this.createPatternJournalBody(journals),
      department_id: getId(pattern.department),
      user_organization_id: getId(pattern.userOrganization),
    }
  },
  createPatternJournalBody(companyJournals) {
    return companyJournals.map((journal) => {
      const params = {}
      for (const [key, value] of Object.entries(journal)) {
        if (
          [
            'id',
            'free_text_1',
            'free_text_2',
            'debit_sub_account',
            'credit_sub_account',
          ].includes(key)
        ) {
          continue
        }
        if (key == 'description') {
          params[key] = value || ''
          continue
        }
        params[`${key}_id`] = getId(value)
      }
      return params
    })
  },
}
