/**
 *    const payMonth = {name: string, value: number}
 *    const payDay = {name: string, value: number}
 *    @return {payMonthList[]payMonth, payDayList[]payDay}
 */
export const getPaySiteChoice = () => {
  const payMonthList = []
  const payDayList = []
  for (let i = 0; i <= 12; i++) {
    if (i === 0) {
      payMonthList.push({ name: '当月', value: i })
    } else {
      payMonthList.push({ name: i + 'ヶ月後', value: i })
    }
  }
  for (let i = 31; i >= 1; i--) {
    if (i === 31) {
      payDayList.push({ name: '月末', value: i })
    } else {
      payDayList.push({ name: i + '日', value: i })
    }
  }
  return { payMonthList, payDayList }
}
