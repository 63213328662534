import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CheckImportCopaniesClient extends APIClient {
  async post(token, data) {
    const payload = {
      data,
      url: URLs.COMPANIES_CHECK_IMPORT,
      method: 'POST',
      headers: {
        Authorization: token,
      },
    }
    try {
      const response = await this.sendRequest(payload)
      return response.data
    } catch (err) {
      throw err.response.data.error
    }
  }
}
export default new CheckImportCopaniesClient()
