import { getCompanyJournalModel } from '@/objects/company/companyPattern'
import { clearOne } from 'Helpers/journal'

export const companyJournalService = {
  appendJournal(journals, index, last, dataToAdd) {
    if (last) {
      journals.push(dataToAdd)
    } else {
      journals.splice(index + 1, 0, dataToAdd)
    }
  },
  add(journals, index, last) {
    const companyJournal = getCompanyJournalModel()
    this.appendJournal(journals, index, last, companyJournal)
  },
  copy(journals, index, last) {
    const dataToAdd = JSON.parse(JSON.stringify(journals[index]))
    this.appendJournal(journals, index, last, dataToAdd)
  },
  clear(journals, index, clearAll) {
    if (clearAll) {
      journals.forEach((journal) => {
        clearOne(journal)
      })
    } else {
      clearOne(journals[index])
    }
  },
  delete(journals, index, deleteAll) {
    if (journals.length === 1) {
      throw new Error('Cannot delete')
    }
    if (deleteAll) {
      journals.splice(1, journals.length)
      clearOne(journals[0])
    } else {
      journals.splice(index, 1)
    }
  },
}
