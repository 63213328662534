import APIClient from '@/clients/_helpers/client'
import URLs from '@/clients/_urls'

class CompanyPatternClient extends APIClient {
  async put({ token, id, data }) {
    const payload = {
      data,
      url: URLs.COMPANY_PATTERN,
      method: 'PUT',
      headers: { Authorization: token },
      args: {
        pattern_id: id,
      },
    }
    const response = await this.sendRequest(payload)
    return response.data.pattern
  }
  async post({ token, data }) {
    const payload = {
      data,
      url: URLs.COMPANY_PATTERNS,
      method: 'POST',
      headers: { Authorization: token },
    }
    const response = await this.sendRequest(payload)
    return response.data.pattern
  }
  async delete({ token, id }) {
    const payload = {
      url: URLs.COMPANY_PATTERN,
      method: 'DELETE',
      headers: { Authorization: token },
      args: {
        pattern_id: id,
      },
    }
    const response = await this.sendRequest(payload)
    return response.data
  }
}

export default new CompanyPatternClient()
