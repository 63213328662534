/*
1. front end バリデーション
2. サンプルデータのエクスポート
*/
import CsvReader from 'Helpers/csv'
import Encoding from 'encoding-japanese'
import checkImportCopaniesClient from '@/clients/company/checkImportCompanies'
import { getUserBankSimpleText } from '@/helpers/account/index'

export const importCompaniesService = {
  async check(token, csvData) {
    const { companies } = await checkImportCopaniesClient.post(token, csvData)
    companies.forEach((item) => {
      if (item.user_bank) {
        item.user_bank.simpletext = getUserBankSimpleText(item.user_bank)
      }
    })
    return companies
  },
}

export const companyExportDocumentId = 'company-table-import-company'
export const companyCsv = {
  read() {
    return CsvReader.read(companyExportDocumentId)
  },
  async exportSample() {
    const sampleData = getSampleData()
    const blob = new Blob([sampleData], { type: 'text/csv' })
    return blob
  },
}
const getSampleData = () => {
  const transpose = (a) => a[0].map((_, c) => a.map((r) => r[c]))
  const convertUtfToSJisList = (utfString) => {
    const unicodeList = utfString.split('').map((char) => char.charCodeAt())
    return Encoding.convert(unicodeList, 'sjis', 'unicode')
  }
  const data = {
    ID: ['1', '2', '2'],
    請求元: ['テスト株式会社', 'テスト2株式会社', ''],
    住所: [
      '東京都中央区日本橋1-1-1 xxxビル',
      '東京都中央区日本橋1-1-2 xxxビル',
      '',
    ],
    電話番号: ['03-xxxx-xxxx', '03-yyyy-yyyy', ''],
    メールアドレス: ['', 'sample.sam@domain.com', ''],
    金融機関コード: ['0001', '0001', ''],
    支店コード: ['001', '001', ''],
    口座種別: ['普通', '普通', ''],
    口座番号: ['0001', '0001', ''],
    口座名義人名: ['テスト(カ', 'テスト２(カ', ''],
    支払方法: ['現金', 'クレジット', ''],
    支払サイト: ['翌月末', '翌月15日', ''],
    振込元金融機関コード: ['0001', '0001', ''],
    振込元支店コード: ['001', '001', ''],
    振込元口座種別: ['普通', '普通', ''],
    振込元口座番号: ['0001', '0001', ''],
    手数料負担: ['1', '0', ''],
    源泉: ['0', '1', ''],
    源泉の区分: ['', '0', ''],
    源泉の対象人数: ['', '5', ''],
    借方勘定科目コード: ['A001', 'A003', 'A005'],
    借方補助科目コード: ['SUB001', '', ''],
    借方部門コード: ['D001', '', ''],
    借方取引先コード: ['V001', 'V003', ''],
    借方プロジェクトコード: ['P001', '', ''],
    借方セグメントコード: ['S001', '', ''],
    借方品目コード: ['I001', '', ''],
    借方タグコード: ['TAG001', '', ''],
    借方口座コード: ['W001', 'W003', 'W003'],
    借方税区分コード: ['TAX001', 'TAX003', 'TAX005'],
    貸方勘定科目コード: ['A002', 'A004', 'A006'],
    貸方補助科目コード: ['', '', ''],
    貸方部門コード: ['D002', '', ''],
    貸方取引先コード: ['V002', 'V004', ''],
    貸方プロジェクトコード: ['P002', '', ''],
    貸方セグメントコード: ['S002', '', ''],
    貸方品目コード: ['I002', '', ''],
    貸方タグコード: ['TAG002', '', ''],
    貸方口座コード: ['W002', '', ''],
    貸方税区分コード: ['TAX002', 'TAX004', 'TAX005'],
    摘要: ['', '委託費用', '雑費'],
  }
  const [headers, values] = [[], []]
  for (const [header, items] of Object.entries(data)) {
    headers.push(header)
    values.push(items)
  }
  const csvDataArray = [headers, ...transpose(values)]
  const csvDataString = csvDataArray.join('\n')
  const jisList = convertUtfToSJisList(csvDataString)
  const uInt8List = new Uint8Array(jisList)
  return new Blob([uInt8List], { type: 'text/csv' })
}
