<template>
  <v-card tile outlined class="sweeep-dialog">
    <v-toolbar class="border-bottom-1" height="50" flat>
      {{ cardTitle }}
    </v-toolbar>
    <v-card-text :settings="settings">
      <v-form ref="patternForm">
        <v-text-field
          v-model="companyPattern.name"
          :readonly="!editable"
          :rules="patternRules.name"
          label="仕訳パターン名"
          filled
          outlined
          dense
        />
        <v-card outlined class="mb-5">
          <v-card-title> このパターンが適用される条件 </v-card-title>
          <v-card-text>
            <!--
            <v-autocomplete
              v-model="companyPattern.department"
              :items="departmentList"
              item-text="name"
              label="部門"
              return-object
              filled
              outlined
              dense
            />
          -->
            <v-autocomplete
              :value="companyPattern.userOrganization"
              :items="userOrganizations"
              item-text="name"
              label="アップロードしたユーザ"
              :readonly="!editable"
              clearable
              return-object
              filled
              outlined
              dense
              persistent-hint
              hint="アップロードしたユーザが指定のユーザだった場合に適用されます"
              class="mb-3"
              @change="companyPattern.userOrganization = $event || {}"
            />
            <v-text-field
              v-model="companyPattern.keyword"
              v-tooltip="
                'キーワードを複数指定する場合は、スペースで区切ってください'
              "
              :readonly="!editable"
              :rules="patternRules.keyword"
              label="請求書内のキーワード"
              filled
              outlined
              dense
              persistent-hint
              hint="指定したキーワードすべてがOCRにより見つかった場合に適用されます"
            />
          </v-card-text>
        </v-card>
      </v-form>
      <div
        v-for="(journal, journalIndex) in patternJournals"
        :key="`pattern-journal-${journalIndex}`"
        class="mr-3"
      >
        <journal-form-header no-padding />
        <v-row no-gutters>
          <v-col
            v-for="dc in ['debit', 'credit']"
            :key="`${dc}-${journalIndex}`"
            cols="6"
          >
            <v-card
              tile
              outlined
              class="pb-5"
              style="background-color: #f5f5f5"
            >
              <journal-tab
                v-if="dc === 'credit'"
                :index="journalIndex + 1"
                :count="patternJournals.length"
                :show-menu="editable"
                :position-left="'100%'"
                @add:next="handleRecordEvent(journalIndex, 'add', false)"
                @add:last="handleRecordEvent(journalIndex, 'add', true)"
                @copy:next="handleRecordEvent(journalIndex, 'copy', false)"
                @copy:last="handleRecordEvent(journalIndex, 'copy', true)"
                @clear:this="handleRecordEvent(journalIndex, 'clear', false)"
                @clear:all="handleRecordEvent(journalIndex, 'clear', true)"
                @delete:this="handleRecordEvent(journalIndex, 'delete', false)"
                @delete:all="handleRecordEvent(journalIndex, 'delete', true)"
              />
              <div class="px-2 pt-2">
                <journal-iterators
                  :journal="journal"
                  :editable="editable"
                  :entry-side="dc"
                  @change="
                    $emit('change:journal', {
                      ...$event,
                      journal: companyPattern.journals[journalIndex],
                    })
                  "
                />
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-card tile outlined style="background-color: #f5f5f5">
          <v-row no-gutters>
            <journal-text-field
              v-model="journal.description"
              description-field
              :chip="sliceText(definition.description, 0, 4)"
              :tooltip="definition.description"
              :editable="editable"
              :mode="displaySetting.description"
              :counter="displaySetting.maxlen_description"
              :check-byte="displaySetting.wordOrByte == 2"
              :class="editable ? 'pa-3 pb-5 mt-5' : 'pa-3 mt-3 mb-3'"
            />
          </v-row>
        </v-card>
      </div>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn @click="$emit('cancel')"> キャンセル </v-btn>
      <v-btn color="primary" :disabled="!editable" @click="updatePattern()">
        {{ submitButtonText }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { sliceText } from 'Helpers/journal'
import Validator from 'Helpers/rules'

import JournalTab from 'Components/Journal/JournalTab.vue'
import JournalFormHeader from 'Components/Journal/JournalFormHeader.vue'
import JournalIterators from 'Components/Journal/JournalIterators.vue'
import JournalTextField from 'Components/Journal/JournalTextField.vue'
export default {
  components: {
    JournalTab,
    JournalFormHeader,
    JournalIterators,
    JournalTextField,
  },
  props: {
    userOrganizations: {
      type: Array,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    companyPattern: {
      type: Object,
      required: true,
    },
    operation: {
      type: String,
      validator: (operation) => ['add', 'copy', 'edit'].includes(operation),
      default: 'edit',
    },
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 160,
      },
      patternRules: Validator.companyPattern.rules,
      formIsValid: false,
    }
  },
  computed: {
    ...mapGetters(['displaySetting', 'definition']),
    patternJournals() {
      return this.companyPattern.journals.map((journal) => {
        journal['debit_sub_account'] = journal.debit_subject
        journal['credit_sub_account'] = journal.credit_subject
        return journal
      })
    },
    cardTitle() {
      const operationToText = {
        add: 'パターンの追加',
        copy: 'パターンのコピー',
        edit: 'パターンの編集',
      }
      return operationToText[this.operation]
    },
    submitButtonText() {
      const operationToText = {
        add: '追加',
        copy: 'コピー',
        edit: '更新',
      }
      return operationToText[this.operation]
    },
  },

  watch: {
    companyPattern: function () {
      this.$refs.patternForm.resetValidation()
    },
  },

  methods: {
    // "departmentList"
    handleRecordEvent(journalIndex, type, flag) {
      const payload = {
        journalIndex,
        type,
        journals: this.companyPattern.journals,
      }
      if (type === 'clear' || type === 'delete') {
        payload.all = flag
        this.$emit('operate:record', payload)
      } else {
        payload.last = flag
        this.$emit('operate:record', payload)
      }
    },
    sliceText(text, i1, i2) {
      return sliceText(text, i1, i2)
    },
    updatePattern() {
      const valid = this.$refs.patternForm.validate()
      if (valid) {
        this.$emit('submit', this.companyPattern)
      }
    },
  },
}
</script>
