<template>
  <v-toolbar flat class="border-bottom-1">
    <v-btn
      v-if="checkPrivilege('company:update')"
      class="mx-2 fs-12"
      @click="readCsv"
    >
      <v-icon x-small class="mr-3" v-text="'ti-import'" />
      <span>インポート</span>
    </v-btn>
    <company-import-hint v-if="checkPrivilege('company:update')" />

    <v-btn
      v-if="checkPrivilege('company:update')"
      v-tooltip="'インポート用のデータサンプルを取得'"
      class="ml-2"
      text
      @click="exportSampleCsv()"
    >
      {{ $t('message.accounting_get_sample') }}
    </v-btn>

    <div>
      <input
        :id="companyExportDocumentId"
        :style="{ display: 'none' }"
        type="file"
      />
    </div>
    <v-btn
      v-if="checkPrivilege('company:export')"
      class="ml-2 px-3 fs-12"
      @click="exportCompany"
    >
      <v-icon x-small class="mr-3" v-text="'ti-export'" />
      <span>エクスポート</span>
    </v-btn>
    <v-btn
      v-if="deleteableCompany"
      class="mx-2"
      @click="$emit('delete:companies')"
    >
      <v-icon x-small class="mr-2" v-text="'ti-trash'" />
      <span>一括削除</span>
    </v-btn>
    <v-checkbox
      :input-value="!showAllCompany"
      class="ml-2 mt-4"
      label="有効な学習データのみ表示"
      @change="$emit('switch:company-filter', !!$event)"
    />
    <v-spacer />

    <v-text-field
      v-tooltip="
        '入力した文字とマッチ（部分一致）する請求書を抽出します。請求書に紐づく全ての項目に対してフィルタを適用します。'
      "
      :value="searchBar"
      append-icon="search"
      placeholder="簡単フィルタ"
      hide-details
      clearable
      class="more-dense mx-2"
      style="max-width: 400px"
      dense
      filled
      @input="$emit('change:search-bar', $event || '')"
    />
  </v-toolbar>
</template>

<script>
import Snackbar from 'Helpers/snackbar/index'
import { companyExportDocumentId, companyCsv } from '@/services/company/csv'
import CompanyImportHint from '@/pages/master/Company/@components/@shared/CompanyImportHint/CompanyImportHint'

export default {
  components: {
    CompanyImportHint,
  },
  props: {
    searchBar: {
      type: String,
      required: true,
    },
    showAllCompany: {
      type: Boolean,
      required: true,
    },
    deleteableCompany: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      companyExportDocumentId,
    }
  },
  methods: {
    async readCsv() {
      let csvData
      csvData = await companyCsv.read()
      this.$emit('read:csv', csvData)
    },
    exportSampleCsv() {
      companyCsv.exportSample().then((blob) => {
        const url = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.download = 'company_sample.csv'
        a.href = url
        a.click()
        URL.revokeObjectURL(url)
      })
    },
    exportCompany() {
      try {
        var auth = this.$store.getters.getAuthToken
        var url = this.$store.getters.apiCompanyExportFunction
        let data = {
          organization_id: this.$store.getters.getOrganizationID,
        }
        $.ajax({
          url: url,
          data: data,
          type: 'POST',
          beforeSend: (xhr) => {
            xhr.setRequestHeader('Authorization', auth)
          },
          xhrFields: {
            responseType: 'blob',
          },
          success: (response) => {
            Snackbar.success('データのエクスポートが完了しました。')
            var a = document.createElement('a')
            var url = window.URL.createObjectURL(response)
            a.href = url
            a.download = '請求元リスト.csv'
            document.body.appendChild(a)
            a.click()
            window.URL.revokeObjectURL(url)
          },
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
