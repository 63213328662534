<template>
  <v-form ref="companyProfileForm">
    <v-row no-gutters>
      <v-col class="pa-1" cols="8">
        <v-text-field
          v-model="company.companyName"
          :label="$t('message.company_table_company_name')"
          :rules="formRules.name"
          :readonly="!editable"
          filled
          dense
          outlined
          required
        />
      </v-col>
      <v-col class="pa-1" cols="4">
        <v-text-field
          class="company-profile-invoice-issuer-number"
          v-model="company.invoiceIssuerNumber"
          label="インボイス番号"
          prefix="T"
          placeholder=" "
          :readonly="!editable"
          :rules="formRules.invoiceIssuerNumber"
          filled
          dense
          outlined
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-1" cols="12">
        <v-text-field
          v-model="company.companyAddress"
          :label="$t('message.company_table_company_address')"
          :rules="formRules.address"
          :readonly="!editable"
          placeholder=" "
          filled
          dense
          outlined
        />
      </v-col>
      <v-col class="pa-1" cols="6">
        <v-text-field
          :value="phonenumberWithhyphen"
          :label="$t('message.company_table_company_phonenumber')"
          :rules="formRules.phoneNumber"
          :readonly="!editable"
          placeholder=" "
          filled
          dense
          outlined
          class="px-1"
          @input="company.companyPhonenumber = $event.replace(/-/g, '')"
        />
      </v-col>
      <v-col class="pa-1" cols="6">
        <v-text-field
          v-model="company.companyEmail"
          :label="$t('message.company_table_email')"
          :readonly="!editable"
          :rules="formRules.email"
          placeholder=" "
          filled
          dense
          outlined
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="px-1">
        <v-checkbox
          :input-value="company.gensenzei"
          label="源泉対象"
          :readonly="!editable"
          @change="onChangeGensenzei($event)"
        />
      </v-col>
      <v-col class="pa-1" cols="9">
        <v-autocomplete
          v-model="company.gensenCode"
          :items="$store.getters.gensenType"
          label="源泉税区分"
          placeholder=" "
          item-text="name"
          item-value="code"
          filled
          dense
          outlined
          :disabled="!company.gensenzei"
          :readonly="!editable"
        />
      </v-col>
      <v-col class="pa-1" cols="3">
        <v-text-field
          v-model="company.gensenTotalTargets"
          type="number"
          label="対象人数"
          :rules="formRules.gensenTotalTargets"
          filled
          dense
          outlined
          :disabled="!company.gensenzei"
          :readonly="!editable"
        />
      </v-col>
    </v-row>
    <div class="mt-5 mb-2 fw-bold">支払情報</div>
    <v-row no-gutters>
      <v-col v-if="userBankList.length > 1" class="pa-1" cols="8">
        <v-autocomplete
          v-model="company.userBank"
          :items="userBankList"
          :readonly="!editable"
          label="振込元銀行"
          placeholder=" "
          item-text="fulltext"
          item-value="id"
          return-object
          filled
          dense
          outlined
        />
      </v-col>
      <v-col v-if="settingPaidByCustomer" class="pa-1" cols="4">
        <v-checkbox
          v-model="company.paidByCustomer"
          label="振込手数料先方負担"
          dense
          hide-details
          class="mt-0"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class="pa-1" cols="6">
        <v-autocomplete
          v-model="company.payType"
          :items="payTypeList"
          :readonly="!editable"
          label="支払方法"
          placeholder=" "
          item-text="name"
          item-value="id"
          filled
          dense
          outlined
        />
      </v-col>
      <v-col class="pa-1" cols="3">
        <v-autocomplete
          v-model="company.payMonth"
          :items="payMonthList"
          :readonly="!editable"
          label="支払月"
          placeholder=" "
          item-text="name"
          item-value="value"
          filled
          dense
          outlined
        />
      </v-col>
      <v-col class="pa-1" cols="3">
        <v-autocomplete
          v-model="company.payDay"
          :items="payDayList"
          :readonly="!editable"
          label="日"
          placeholder=" "
          item-text="name"
          item-value="value"
          filled
          dense
          outlined
        />
      </v-col>
      <v-col class="pa-1" cols="6">
        <v-autocomplete
          :value="company.bank"
          :items="bankList"
          :readonly="!editable"
          :rules="
            company.payType === 1 || (company.bank && company.bank.name)
              ? formRules.bankName
              : []
          "
          label="金融機関"
          placeholder=" "
          item-text="name_code"
          item-value="name"
          return-object
          filled
          dense
          outlined
          clearable
          @change="
            company.bank = $event || {}
            company.bankBranch = null
          "
        />
      </v-col>

      <v-col class="pa-1" cols="6">
        <v-autocomplete
          :value="company.bankBranch"
          :items="branchList"
          :readonly="!editable"
          :rules="
            company.payType === 1 ||
            (company.bankBranch && company.bankBranch.name)
              ? formRules.branchName
              : []
          "
          label="支店"
          placeholder=" "
          item-text="name_code"
          item-value="name"
          return-object
          filled
          dense
          outlined
          clearable
          @change="company.bankBranch = $event || {}"
        />
      </v-col>
      <v-col class="pa-1" cols="6">
        <v-autocomplete
          v-model="company.bankAccountType"
          :items="bankAccountTypeList"
          :readonly="!editable"
          label="口座種別"
          placeholder=" "
          item-text="name_code"
          item-value="name"
          filled
          dense
          outlined
          clearable
        />
      </v-col>

      <v-col class="pa-1" cols="6">
        <v-text-field
          v-model="company.bankAccountNumber"
          :readonly="!editable"
          maxlength="8"
          label="口座番号"
          placeholder=" "
          filled
          dense
          outlined
        />
      </v-col>
      <v-col class="pa-1" cols="12">
        <v-text-field
          v-model="company.bankAccountName"
          maxlength="48"
          label="口座名義"
          placeholder=" "
          :rules="
            company.payType === 1 || company.bankAccountName
              ? formRules.accountName
              : []
          "
          :readonly="!editable"
          filled
          dense
          outlined
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<style>
.company-profile-invoice-issuer-number .v-text-field__prefix {
  margin-top: 0px !important;
}
</style>

<script>
import { mapGetters } from 'vuex'
import { AsYouType } from 'libphonenumber-js'
import { getPaySiteChoice } from '@/objects/company/paySite'
import { getFormCompanyModel } from '@/objects/company/companyForm'
import { getPayTypeList } from '@/objects/company/payType'
import { correctMasterListByName } from '@/helpers/payment/index'
import Validator from 'Helpers/rules'

const { payMonthList, payDayList } = getPaySiteChoice()
const payTypeList = getPayTypeList()

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    company: {
      type: Object,
      default: () => getFormCompanyModel(),
    },
    userBankList: {
      type: Array,
      required: true,
    },
  },
  data() {
    const bankRules = Validator.bank.rules
    const companyRules = Validator.company.rules
    return {
      formRules: {
        email: Validator.email.getRules('', false),
        ...bankRules,
        ...companyRules,
        invoiceIssuerNumber: [
          (v) => !v || v.replace(/\d/g, '').length == 0 || this.$t('message.invalid_input'),
          (v) => !v || v.length == 13 || this.$t('message.invalid_input'),
          (v) => !v || v[0] !== '0' || this.$t('message.invalid_input'),
        ],
      },
      payTypeList,
      payMonthList,
      payDayList,
    }
  },
  computed: {
    ...mapGetters(['bankAccountTypeList', 'settingPaidByCustomer']),
    phonenumberWithhyphen() {
      return new AsYouType('JP').input(this.company.companyPhonenumber)
    },
    bankList() {
      let bankName = ''
      let bankCode = ''
      const bank = this.company.bank
      if (bank) {
        bankName = bank.name ? bank.name : ''
        bankCode = bank.code ? bank.code : ''
      }
      let bankList = this.$store.getters.bankList
      bankList = correctMasterListByName(bankList, bankName, bankCode)
      return bankList
    },
    branchList() {
      let bankName = ''
      let branchName = ''
      let branchCode = ''
      const bank = this.company.bank
      if (bank) {
        bankName = bank.name ? bank.name : ''
      }
      let branchList = this.$store.getters.bankBranchList[bankName]
      branchList = branchList ? branchList : []

      const bankBranch = this.company.bankBranch
      if (bankBranch) {
        branchName = bankBranch.name ? bankBranch.name : ''
        branchCode = bankBranch.code ? bankBranch.code : ''
      }
      branchList = correctMasterListByName(branchList, branchName, branchCode)
      return branchList
    },
  },
  methods: {
    validateForm() {
      return this.$refs.companyProfileForm.validate()
    },
    onChangeGensenzei(isGensen) {
      this.company.gensenzei = isGensen
      if (!isGensen) {
        this.company.gensenTotalTargets = 0
        this.company.gensenCode = ''
      }
    },
  },
}
</script>
